.actionButton {
  border-radius: 7px !important;
  color: white !important;

}

.filter {
  width: 80%;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 2%;
}

.ant-btn:hover,
.ant-btn:focus {
  border: 0;
}
