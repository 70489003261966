footer {
  display: block;
}
.color-footer-dark-text,
footer.footer--dark,
footer.footer--dark .footer__big .text_widget p,
footer.footer--dark .footer__big .widget ul.links li a,
footer.footer4 .footer__small .info_links li a,
footer.footer4.footer--colored,
footer.footer4 .footer4_text,
footer.footer5.footer--bw,
footer.footer5.footer--bw .footer__big .widget ul.links a {
  color: rgba(255, 255, 255, 0.5);
}
footer.footer5.footer--bw .footer__big {
  background: #111115;
  margin-top: 30px;
}
footer .footer__small {
  background: #272626;
}
footer .footer__big {
  padding: 50px 0 30px;
}
footer .footer__small p {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  padding: 30px 0;
}
.sm-sty {
  padding: 0 5px;
text-align: center;
}
ul {
  list-style: none;
  padding: 0;
}
footer .footer__big .widget .widget__title {
  font-size: 1.06667rem;
  text-transform: uppercase;
  line-height: normal;
  font-weight: 600;
  position: relative;
  color: #ffffff;
}
footer .footer__big .widget {
  margin-bottom: 4rem;
}
.flex-wrap {
  justify-content: center;
}
.links {
  font-size: 14px;
  color: #ffffff;
}
.align-center {
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  text-align: center;
}
.footer-wrap {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  padding: 30px 30px;
}
.footer-txt-left {
  text-align: center;
}
.footer-txt-right {
  text-align: right;
}
@media screen and (max-width: 765px) {
  .footer-txt-left {
    text-align: center;
  }
  .footer-txt-right {
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .footer-move-mobile {
    margin-top: 35%;
  }
}

@media only screen and (max-width: 764px) {
footer .footer__big .widget {
  margin-bottom: 0;
}
}
