@import "../../../themeColor.less";

.navbar {
  height: 70px;
  padding: 0rem 1rem !important;
  background: #be3800 !important;
  // border-bottom: 1px solid #f0f0f0;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// .ant-btn-primary {
//   color: #313030 ;
//   background: #ffffff ;
//   border-color: #ffffff ;
// }
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #a9aaac;
}
.btn-primary:hover {
  color: #fff;
  background-color: @primary-color;
  border-color: #f8f9fa;
}
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: @primary-color;
  border-color: #f8f9fa;
}
.btn-primary:focus {
  color: #fff;
  background-color: @primary-color;
  border-color: #f8f9fa;
}
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: @primary-color;
  border-color: #f8f9fa;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #f8f9fa;
}
.nav-wrapper {
  border-bottom: 1px solid #e5e5e5;
}
.userIcon {
  width: 30px;
}
.logo {
  width: 55px;
  border-radius: 50%;
  margin-bottom: 11px;
}
.logo-text {
  width: 150px;
  padding-left: 2px;
}
.navbar-text {
  padding-right: 1rem;
}
.navbar-brand {
  font-size: 3.25rem;
}
.stats-wrapper {
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px !important;
  justify-content: center;
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: #7f7f7f;
  font-size: 18px;
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
  font-size: 18px;
  color: #3f3f3f;
}
.center-text {
  text-align: center;
}
.profile-p-val {
  font-size: 18px;
  margin-bottom: 0;
  font-family: "montserrat";
}
.profile-p {
  font-size: 30px;
  font-family: "montserrat";
  font-weight: 500;
  margin-bottom: 10px !important;
}
.img-avatar {
  line-height: 63px !important;
  margin-bottom: 20px !important;
  border: 10px solid #f751168c;
}
.profile-span {
  font-size: 15px;
  font-weight: 300;
  color: #5f5f5f;
}
.profile {
  font-size: 15px;
  font-weight: 300;
  color: #5f5f5f;
}
@media screen and (max-width: 768px) {
  .logo {
    width: 45px;
  }
  .navbar {
    padding: 0 1rem;
    flex-wrap: nowrap;
  }
  .logo-text {
    width: 125px;
  }
  .hidden-md-nav {
    display: none;
  }
}
