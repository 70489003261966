.bg-img {
  background-image: url("../../assets/img/camel-1.png");
}
.wrap {
  margin: 200px auto;
}
.divider {
  margin: 100px 0;
}
.text-center {
  text-align: center;
}
.manual-hd-text {
  font-size: 80px;
  color: #be3800 !important;
  font-weight: 800;
}
.manual-sb-text {
  font-size: 40px;
  color: #be3800 !important;
  font-weight: 700;
}
.manual-body-text {
  font-size: 16px;
  font-weight: 500;
}
.mobile {
  width: 400px !important;
}
.manual-body-text-2 {
  font-size: 16px;
  font-weight: 500;
}
.steps-padding {
  padding: "0 300px";
}
@media screen and (max-width: 568px) {
  .steps-padding {
    padding: 0 5px;
  }
}
@media screen and (max-width: 568px) {
  .manual-hd-text {
    font-size: 40px;
  }
}
@media screen and (max-width: 568px) {
  .manual-sb-text {
    font-size: 20px;
  }
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: #be3800 !important;
  border-radius: 20px;
  transition: all 0.3s;
}
// @media screen and (max-width: 568px) {
//     .container{
//             padding: 0px;
//   }
// }
