@import "../../themeColor.less";

.loginWrap {
  background: #f86912;
  padding: 2rem;
  height: 200px;
  position: absolute !important;
  width: 100%;
}
.ant-image-img {
  display: block;
  width: 100%;
  height: auto;
  color: aqua;
}

.loginContainer {
  background: white;
  top: 100px;
  z-index: 1;
  position: relative;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  padding: 3rem 4rem;
  width: 55%;
  margin: auto;
  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 1rem 1rem;
  }
}

.headerText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 27px;
  color: black;
  margin-bottom: 11%;
  margin-left: 8.5%;
}

.bulletPoints {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 11%;
}
.bg-img {
  background-image: url("../../assets/img/camel-1.png");
  background-repeat: repeat;
}
.orange {
  background-color: #f86912 !important;
}
.pclass {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}

.hide-sm {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.hide-lg {
  @media (min-width: 1000px) {
    display: none !important;
  }
}

.loginbtn {
  background: @primary-color !important;
  border-radius: 7px !important;
  color: white !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center;

  &:disabled {
    color: @primary-color !important;
    background-color: white !important;
  }
}
