@import "../../themeColor.less";

h1 {
  color: @primary-color;
}
h3 {
  color: @text-color !important;
  font-weight: 700;
  font-size: 22px !important;
}

.offer-header-wrapper {
  row-gap: 0px;
  background: #ff9a47 !important;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  width: 55%;
}
.ant-row {
  background: white;
  display: flex;
  flex-flow: row wrap;
}
.offer-inner-header {
  height: 36px;
  align-self: center;
  text-align: center;
  border: 1px solid #2e2e2e;
  margin: 6px;
  padding: 5px 0;
  background: white;
  border-radius: 10px;
  color: #f86912;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.offer-inner-header-bg {
  height: 36px;
  align-self: center;
  text-align: center;
  margin: 8px 0;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  background: #ff9a47;
}

.ant-tabs-content-holder {
  padding-top: 0px !important;
}
@media screen and (max-width: 790px) {
  .offer-header-wrapper {
    width: 100%;
  }
}
