@primary-color: #BE3800; // primary color for all components
@secondary-color: #A0BCD1;
@link-color: #FF9A47; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #D32F2F; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #f0f0f0; // major border color
@light-gray: #f0f0f0; // disabled backgroung
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
