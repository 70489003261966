@import "../../themeColor.less";

.Appeal-container {
  p {
    text-align: justify;
  }
  .header-style {
    justify-content: center;
    padding: 50px 0px;
  }
  .body-style {
    padding: 0 200px 100px;
  }

  @media screen and (max-width: 988px) {
    .header-style {
      justify-content: center;
      padding: 20px 10px;
    }
    .body-style {
      padding: 0 10px 10px;
    }
  }
}
