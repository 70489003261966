@import "../../themeColor.less";

.Home-container {
  margin: 0;
  

  .ant-row {
    background: transparent;
}

h1 {
  color: @primary-color;
}

.hero-img{
  height: 550px;
}

.home-header {
  background-image: url("../../assets/img/landing-bg.png") !important;
 background-size: cover;
  background: linear-gradient(101.41deg, #BE3800 0%, #F86912 83.68%) !important;
}
.home-h1-text {
  color: #ffffff !important;
  font-size: 32px;
  font-weight: 700;
}

.home-p-text {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.home-wrap {
    margin: 120px 40px;
    z-index: 100;
}
.icon-wrap {
  margin-bottom: 30px;
}
.current-trades-top {
  min-height: 116px;
  background: #333333;
  
}
.current-table {
  padding: 50px 0;
}
.camel-text {
  font-size: 32px;
  font-weight: 700;
}
.camel{
  margin: 50px 30px 0;
  text-align: center;
}
.home-contact {
  padding: 50px 0;
  background: #ffffff;
  color: #3F3F3F !important;
;
}
.home-current-text {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 600;
  padding:  20px;
}
.home-contact-text {
  color: #3F3F3F !important;
  font-size: 14px;
  font-weight: 400;
  padding:  20px 40px;
  background-color: #ffffff;
  text-align: center;
}
.btn-wrap {
  justify-content: left;
}
.vertical-line {
  border: 1px solid #cccaca !important;
transform: rotate(180deg);
height: 226px;

}
span.anticon {
  padding-bottom: 30px;
}
.ant-table {
  background: #ffffff1a;
}
@media screen and (max-width: 768px) {
  .vertical-line{
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .vertical-line{
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .home-header {
    background: linear-gradient(101.41deg, #BE3800 0%, #F86912 83.68%) !important;
    max-width: 100%;
    min-height: 550px;
    padding-bottom: 20px;
    
    min-height: 420px;

    ;
  }
.home-h1-text {
  color: #ffffff !important;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.home-p-text {
  color: #ffffff !important;

  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.btn-wrap {
  justify-content: center;
}
.margin-rightt {
  margin-right: 40px !important;
}
.icon-wrap {
  margin: 20px 40px;
  text-align: center;
  justify-content: center;
}
}
@media screen and (max-width: 988px) {
  .icon-wrap {
    margin: 20px 40px;
    text-align: center;
    justify-content: center;
  }
  .btn-wrap {
    justify-content: center;
  }
  .home-header {
    min-height: 420px;
}
.home-p-text {
  color: #ffffff !important;
font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.btn-mob {
  text-align: center;
  margin-top: 20px;
}
.margin-rightt {
  margin-right: 0px !important;
}
}
}