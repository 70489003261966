.table-wrapper {
  width: 80%;
  margin: 20px auto;
  overflow: scroll;
  //   border: 1px solid #f0f0f0;

  .top-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f0f0;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none;
    color: black;
    height: 66px !important;
    font-size: 20px;
    font-weight: 600;
  }

  h2 {
    font-size: 17px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    white-space: nowrap;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}
