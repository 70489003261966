@import "../../../themeColor.less";

b {
  font-weight: 500 !important;
}

.order-container {
  min-height: 100vh;
  padding-top: 5%;
  width: 100%;

  .anticon svg {
    color: #4f9a94 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #4f9a94 !important;
    // background-color: #4F9A94 !important;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f86912 !important;
  }
}

.content {
  width: 100%;
  //   height: 470px;

  background: #ffffff;
  box-shadow: -1px -1px 4px #a3a3a321, 1px 1px 4px 2px #cecece2f;

  .content-border {
    border-right: 1.5px solid rgba(47, 42, 45, 0.25);
    // height: 470px;
    padding: 4%;

    @media only screen and (max-width: 768px) {
      //   border: 0;
      border-bottom: 1.5px solid rgba(47, 42, 45, 0.25);
      width: 100%;
      border-radius: 20px;
    }

    p {
      margin-bottom: 5% !important;
    }
    .ant-descriptions-item-content {
      font-weight: 600;
    }
    .price {
      color: #d32f2f;
    }
    .username-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      // font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #2f2a2d;

      @media only screen and (max-width: 575px) {
        font-size: 15px;
      }
    }
    .user-progress {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #7f7f7f;

      @media only screen and (max-width: 575px) {
        font-size: 15px;
      }
    }

    .bold-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #3f3f3f;
      @media only screen and (max-width: 575px) {
        font-size: 15px;
      }
    }
    .remark {
      background: #fbfbfb;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #b1afaf;
      padding: 2%;
      min-height: 95px;
      min-width: 100%;
    }
  }

  .payment-form {
    padding: 1%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    width: 100%;
    color: #d32f2f;
    padding-bottom: 5%;
  }

  .confirm-payment {
    padding: 3%;
    width: 100%;
    h2 {
      // border-bottom: 1px solid #ccc;
      // padding-bottom: 2%;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
      /* identical to box height */

      color: #3f3f3f;
    }
    .row-top {
      border-bottom: 1px solid #ccc;
      margin-bottom: 2%;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;

      color: #3f3f3f;
    }
  }
}
.payment-method {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 1px 8px;
  color: #3f3f3f;
  background: #fafafb;
}

.timer {
  text-align: right;
  @media only screen and (max-width: 768px) {
    padding-top: 4% !important;
  }
  @media only screen and (min-width: 550px) {
    padding-top: 4% !important;
  }
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-left: 5% !important;

  @media (max-width: 320px) {
    padding-left: 2% !important;
  }

  @media only screen and (max-width: 575px) {
    padding-left: 2% !important;
  }

  @media (min-width: 768px) {
    padding-left: 7% !important;
  }
  @media (min-width: 1024px) {
    padding-left: 5% !important;
  }
}

.ant-input-number-lg {
  width: 70% !important;
  @media only screen and (max-width: 320px) {
    width: 65% !important;
  }
}

.warning-container {
  background: rgba(243, 32, 19, 0.09);
  border: 1px solid #f32013;
  box-sizing: border-box;
  padding: 1%;
}

.orderDetails {
  padding: 4%;
  border-bottom: 1px solid #e5e5e5;
  background: rgba(127, 127, 127, 0.06);
  margin: 0;
}

h2,
h5 {
  @media only screen and (max-width: 575px) {
    font-size: 20px !important;
  }
}

.button {
  &:disabled {
    color: @primary-color !important;
    background-color: white !important;
  }
}

.ant-statistic-content-value {
  font-size: 20px !important;
}

.ant-statistic-title {
  font-size: 22px !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #4f9a94 !important;
  border: 1px solid #4f9a94;
}

.hide-mobile {
  @media only screen and (max-width: 450px) {
    display: none !important;
  }
}

.order-container
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #509a94 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #509a94 !important;
}

.confirm-code {
  text-align: center;
  background: #be3902;
  color: white !important;
  width: 53%;
  padding: 2%;
  margin: 4% auto;
  border-radius: 10px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5 !important;
  border-color: #f0f0f0 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-statistic-content {
  .ant-statistic-content-value {
    color: red !important;
    font-size: 14px !important;
  }

  color: red !important;
  font-size: 14px !important;
}

.rejected {
  .order-container .anticon svg {
    color: #be3900 !important;
  }
}
.phoneLink {
  color: black;
  text-decoration: underline;
}
