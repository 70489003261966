  .table-wrapper {
    width: 100%;
  }
  .filter {
    width: 100%;
    margin: auto;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  
  .ant-btn:hover,
  .ant-btn:focus {
    border: 0;
  }
  
.ant-btn-primary {
  color: #fff;
  background: #BE3800 !important;
  border-color: #BE3800 !important;
  border-radius: 7px !important;
}