.tabs {
  &.ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-wrap,
  .ant-tabs-content-holder {
    padding-top: 25px;
  }
}
