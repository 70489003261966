@import "../../../themeColor.less";

.nav-link {
  color: #3f3f3f;
  font-size: 16px;
  text-decoration: none;
  background-color: transparent;
  font-weight: 700;
}
a:hover {
  color: #f9690c;
  text-decoration: underline;
}
.menu-border {
  border-bottom: 1px solid @light-gray;
  padding-top: 10px;
}
.ant-menu-horizontal {
  background: #ff9a47 !important;
  line-height: 46px;
  border: 0;
  border-bottom: none !important;
  box-shadow: none;
  color: #ffffff !important;
}
.offer-btn {
  margin-left: 550px;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #ffffff !important;
  font-weight: bold !important;
  ::before {
    box-sizing: border-box;
    color: white !important;
  }
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fafafa !important;
  border-bottom: 3px solid #fafafa !important;
}
.hidden-md {
  display: none;
}
a::before {
  color: #ffffff !important;
}
@media screen and (max-width: 768px) {
  .offer-btn {
    margin-left: 0;
  }
}

.hide-offer-mobile {
  margin-left: 550px !important;

  @media screen and (max-width: 1024px) {
    margin-left: 375px !important;
  }

  @media screen and (max-width: 768px) {
    margin-left: 150px !important;
  }

  @media screen and (max-width: 425px) {
    margin-left: 0 !important;
  }
}

.hide-mobile {
  @media screen and (max-width: 425px) {
    display: none !important;
  }
}
