.myOffers {
  .table-wrapper {
    width: 100%;
  }
  .filter {
    width: 100%;
    margin: auto;
    margin-top: 4%;
    margin-bottom: 2%;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    border: 0;
  }
  .ant-spin-container {
    position: relative;
    transition: opacity 0.3s;
    background: #fdcfb8;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #fdcfb8 !important;
  }
  .ant-table {
    cursor: pointer;
  }

  .ant-picker {
    display: block !important;
  }
  .hide-lg {
    @media only screen and (max-width: 728px) {
      display:  inline-block !important;
      margin-right: 5%;
    }
  }
}
