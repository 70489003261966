  .table-wrapper {
    width: 100%;
  }
  .filter {
    width: 100%;
    margin: auto;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  
  .ant-btn:hover,
  .ant-btn:focus {
    border: 0;
  }
  