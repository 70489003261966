.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 7px;
    color: #3f3f3f;
    background: #fff;
    border-color: #fff;
}

.ant-btn-primary {
    color: #fff;
    background: #BE3800 !important;
    border-color: #BE3800 !important;
    border-radius: 7px !important;
}
.ant-btn-secondary {
    border-radius: 7px !important;
}