.offerForm {
  .ant-input-group {
    width: 100% !important;
  }
  .saveBtn {
    justify-content: flex-start;
  }
  .confirm-offer-modal {
    background-color: rgb(242, 242, 242);
    height: 45px;
    width: 288px;
    border: 1px solid rgb(229, 229, 229);
    margin-bottom: 20px;
    justify-content: space-between;
    border-radius: 5px;
    row-gap: 0px;
    padding: 10px;
    color: rgb(177, 175, 175);
  }
  .confirm-offer-modal-remark {
    background-color: rgb(242, 242, 242);
    height: 160px;
    width: 100%;
    border: 1px solid rgb(229, 229, 229);
    margin-bottom: 20px;
    justify-content: space-between;
    border-radius: 5px;
    row-gap: 0px;
    padding: 10px;
    color: rgb(177, 175, 175);
  }
  .disabled-wallet {
    height: 26px;
    width: 26px;
  }
  .top-limit-to {
    padding-top: 40px;
    text-align: center;
  }
  .top-limit-to-limit {
    padding-top: 30px;
  }
  .ant-input-group .ant-input {
    float: right !important;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  @media screen and (max-width: 768px) {
    .top-limit-to {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .top-limit-to-limit {
      padding-top: 0px;
    }
  }
  .approximate {
    margin-right: 20px;
    padding-top: 35px;
  }
  .approximate-to {
    margin-top: 28px;
    height: 42px;
  }

  @media screen and (max-width: 768px) {
    .approximate {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .approximate-to {
      padding-top: 10px;
      margin-left: 10px;
    }
  }

  .ant-picker {
    display: block !important;
  }
}
