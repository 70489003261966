@import "~antd/dist/antd.less";
@import "~antd/lib/style/themes/default.less";
@import "./themeColor.less";
@import 'react-multi-carousel/lib/styles.css';

.App {
  text-align: center;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-row {
  background: white;
  display: flex;
  flex-flow: row wrap;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #A0BCD1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-moz-selection {
  /* Code for Firefox */
  // color: red;
  background: @primary-color;
}

::selection {
  // color: red;
  background: @primary-color;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0px 10px;
  line-height: 0;
}
.ant-table-pagination.ant-pagination {
  padding: 16px 0 !important;
  margin: 0 !important;
  background: white;
}
.ant-btn-primary {
  color: #fff;
  background: #BE3800;
  border-color: #BE3800;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #f8691294;
  border-color: #f8691294;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #545454!important;
  font-weight: 500;
}

